<template>
  <component :is="component" />
</template>

<script>
import SubscribeUser from './SubscribeUser.vue'
import SubscribeProvider from './SubscribeProvider.vue'

export default {
  computed: {
    component () {
      return this.$isA('provider') ? SubscribeProvider : SubscribeUser
    },
  },
}
</script>
