<template>
  <div class="card card-authentication">
    <div class="card-body">
      <router-link :to="{ name: 'login' }">
        <img
          class="logo"
          src="@/assets/img/logo/primary-stacked.svg"
          alt="MedCPD"
        />
      </router-link>

      <h2>Select Plan</h2>

      <ul class="list-data list-data-plan-select no-hover no-shadow">
        <subscription-user-tier-item
          v-for="pricing in subscriptionPlan.pricings"
          :key="pricing.id"
          :pricing="pricing"
          v-model="selected"
        />
      </ul>

      <h2>Payment Details</h2>

      <p class="text-muted">
        Please enter your payment details below so they can be saved for subscription payments.
      </p>

      <p class="text-muted">
        You will not be charged until the start of your first billing period.
      </p>

      <add-card :click="addCard" @tokenize="(t) => (tokenize = t)" />

      <div v-if="errorMessage" class="text-center text-danger">{{ errorMessage }}</div>
    </div>
  </div>
</template>

<script>
import { call, get } from 'vuex-pathify'
import SubscriptionUserTierItem from '@/components/subscriptions/SubscriptionUserTierItem.vue'
import AddCard from '@/components/payments/AddCard.vue'
import { http } from '@/utils'
import { authMethods } from '@/vuex/helpers'

export default {
  components: {
    SubscriptionUserTierItem,
    AddCard
  },

  data () {
    return {
      selected: null,
      tokenize: null,
      errorMessage: null,
    }
  },

  async created () {
    await this.getPackages()

    this.selected = this.subscriptionPlan.pricings.find((pricing) => pricing.billing_frequency === 'month').id
  },

  computed: {
    subscriptionPlan: get('packages/packages'),
  },

  methods: {
    ...authMethods,
    getPackages: call('packages/getPackages'),

    async addCard () {
      if (!this.tokenize) {
        throw new Error('Unable to make stripe payment.')
      }

      try {
        const paymentMethod = await this.tokenize()

        await http.post('/subscription', {
          payment_method: paymentMethod.id,
          package_pricing_id: this.selected,
        })

        this.$router.push({ name: 'dashboard' })
        return
      } catch (err) {
        if (err.response && err.response.status === 422) {
          this.errorMessage = err.response.data.message
        }
      }
    },
  }
}
</script>
