<template>
  <div class="card card-authentication">
    <div class="card-body">
      <router-link :to="{ name: 'login' }">
        <img
          class="logo"
          src="@/assets/img/logo/primary-stacked.svg"
          alt="MedCPD"
        />
      </router-link>

      <template v-if="subscriptionPlan && pricing">
        <template v-if="!isEnterprisePlan">
          <h2>Payment Details</h2>

          <div class="row">
            <div class="col">
              <p class="h4 text-muted">{{ pricing.name }}</p>
            </div>

            <div class="col-auto">
              <p class="text-primary strong">{{ price }} /month</p>
            </div>
          </div>

          <p class="text-muted">
            Please enter your payment details below so they can be saved for subscription payments.
          </p>

          <add-card @tokenize="(t) => tokenize = t" :click="addCard" />

          <div v-if="errorMessage" class="text-center text-danger">{{ errorMessage }}</div>

          <p>
            <small class="text-muted font-italic">
              Prices shown are in Australian Dollars (AUD) and inclusive of GST.
            </small>
          </p>
        </template>

        <template v-else>
          <h2>Enterprise Plan</h2>

          <p class="text-muted">{{ pricing.description }}</p>

          <h2>Contact Us</h2>

          <p class="text-muted">
            To join our enterprise provider plan please get in contact with us
            using the details below so we can tailor a plan for your needs.
          </p>

          <div class="row mb-5">
              <div class="col-auto text-muted">Email:</div>

              <div class="col">
                <a href="mailto:contact@medcpd.com" target="_blank">
                  contact@medcpd.com
                </a>
              </div>
          </div>
        </template>
      </template>

      <div class="mt-5">
        <router-link :to="{ name: 'verification' }" class="text-muted">Back</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { call, get } from 'vuex-pathify'
import AddCard from '@/components/payments/AddCard.vue'
import { http } from '@/utils'
import { authMethods } from '@/vuex/helpers'

export default {
  components: {
    AddCard,
  },

  data () {
    return {
      packageId: null,
      tokenize: null,
      errorMessage: null,
    }
  },

  async created () {
    await this.getPackages()

    if (this.$user.requires_enterprise) {
      this.packageId = this.subscriptionPlan.pricings.find((p) => p.name === 'Enterprise').id
    }

    if (this.$route.query.plan) {
      this.packageId = this.$route.query.plan
    }

    if (this.isEnterprisePlan) {
      http.post('/subscription/enterprise', {})
    }
  },

  computed: {
    subscriptionPlan: get('packages/packages'),

    pricing () {
      if (!this.subscriptionPlan?.pricings) {
        return null
      }

      return this.subscriptionPlan.pricings.find((p) => p.id === parseInt(this.packageId))
    },

    price () {
      return this.formatPrice(this.pricing.price)
    },

    isEnterprisePlan () {
      return this.pricing?.name === 'Enterprise'
    },
  },

  methods: {
    ...authMethods,
    getPackages: call('packages/getPackages'),

    formatPrice (price) {
      return (price / 100).toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    },

    async addCard () {
      if (!this.tokenize) {
        throw new Error('Unable to make stripe payment.')
      }

      try {
        const paymentMethod = await this.tokenize()

        await http.post('/subscription', {
          payment_method: paymentMethod.id,
          package_pricing_id: this.packageId,
        })

        await this.authorize()
        this.$router.push({ name: 'dashboard' })
        return
      } catch (err) {
        if (err.response && err.response.status === 422) {
          this.errorMessage = err.response.data.message
        }
      }
    },
  },
}
</script>
