<template>
  <div class="card card-authentication">
    <validation-observer tag="form" class="card-body" @submit.prevent ref="validation">
      <img class="logo" src="@/assets/img/logo/primary-stacked.svg" alt="Med CPD" />

      <h1>Change Password</h1>

      <c-alert v-if="error" variant="danger">
        {{ error }}
      </c-alert>

      <validation-provider
        tag="div"
        class="mb-3"
        name="password"
        rules="required|min:8|contains_special_character|contains_number|contains_uc_character|contains_lc_character"
      >
        <label>Password</label>
        <c-input v-model="password" type="password" />
        <c-help-block />
      </validation-provider>

      <validation-provider
        tag="div"
        class="mb-3"
        vid="password_confirmation"
        name="password confirmation"
        :rules="{ required: true, is: password }"
      >
        <label>Repeat Password</label>
        <c-input v-model="passwordConfirmation" type="password" />
        <c-help-block />
      </validation-provider>

      <password-strength-bar
        v-bind="{ password, label: 'Password Strength' }"
        class="mb-3"
      />

      <div class="row">
        <div class="col text-end">
          <c-button type="submit" validate @click="setPassword">
            Set Password
          </c-button>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { call } from 'vuex-pathify'
import { toast } from '@chameleon/chameleon-vue'
import PasswordStrengthBar from '@/components/PasswordStrengthBar'

export default {
  components: {
    PasswordStrengthBar
  },

  data () {
    return {
      error: null,
      password: '',
      passwordConfirmation: null
    }
  },

  computed: {
    email () {
      return this.$route.query.email
    },

    token () {
      return this.$route.params.token
    }
  },

  created () {
    if (this.email === undefined || this.email === null) {
      this.$router.replace({ name: 'login' })
    }
  },

  methods: {
    resetPassword: call('auth/resetPassword'),

    async setPassword () {
      try {
        const payload = {
          email: this.email,
          token: this.token,
          password: this.password,
          password_confirmation: this.passwordConfirmation
        }

        await this.resetPassword(payload)

        toast('Password successfully reset.', 'success')

        this.$router.push({ name: 'login' })
      } catch (error) {
        if (error.response && error.response.status === 422 && error.response.data.errors.generic) {
          this.error = error.response.data.errors.generic[0]
        } else {
          this.error = 'Something went wrong, please try again.'
        }
      }
    }
  }

}
</script>
